export const Fernwartung = ({ sidebar }) => (
  <div className="template-sidebar">
    <div className="sidebar">{sidebar}</div>
    <div className="content box">
      <div className="inner">
        <h1>Fernwartung</h1>
        <p>
          Manchmal streikt der PC, und wir fragen uns: "Was nun?" Keine Sorge,
          Sie müssen jetzt nicht zum IT-Experten werden oder das Haus verlassen.
          HanseWeb IT ist Ihr Rettungsanker, und wir erledigen das Problem,
          während Sie gemütlich zu Hause bleiben.
        </p>
        <h2>Warum Unsere Fernwartung Die Beste Wahl ist</h2>
        <p>
          Viele PC-Probleme können einfacher gelöst werden, als Sie denken. Und
          wir wissen wie. Wir bieten schnelle Hilfe per Telefon oder durch
          Fernwartung, und Sie müssen nur ein paar einfache Schritte befolgen.
        </p>
        <h2>Wann ist Fernwartung die richtige Wahl?</h2>
        <ul>
          <li>
            <b>Bei Softwareproblemen:</b> Wenn Sie mit Softwareproblemen zu
            kämpfen haben, die keine physische Anwesenheit erfordern, ist die
            Fernwartung oft die schnellste und effizienteste Lösung.
          </li>
          <li>
            <b>Bei Konfigurationsänderungen:</b> Wenn Sie Einstellungen oder
            Konfigurationen an Ihrem System ändern müssen, die remote zugänglich
            sind, kann Fernwartung eine zeitsparende Option sein.
          </li>
          <li>
            <b>Für Updates und Wartung:</b> Regelmäßige Updates und
            Wartungsarbeiten an Software und Systemen können oft problemlos per
            Fernzugriff durchgeführt werden.
          </li>
          <li>
            <b>Bei Schulungen und Anleitungen:</b> Fernwartung ermöglicht es,
            Schulungen und Anleitungen online durchzuführen, was besonders
            nützlich ist, wenn Sie Unterstützung bei der Verwendung bestimmter
            Anwendungen benötigen.
          </li>
          <li>
            <b>Für schnelle Hilfe:</b> Wenn Sie dringend technische
            Unterstützung benötigen und keine Zeit für einen Vor-Ort-Besuch
            haben, kann die Fernwartung die Antwort sein.
          </li>
          <li>
            <b>Für Kostenersparnis:</b> Fernwartung spart oft Zeit und Geld im
            Vergleich zu Vor-Ort-Besuchen, insbesondere bei kleineren Problemen
            oder routinemäßiger Wartung.
          </li>
        </ul>

        <h2>So Funktioniert's in Drei Schritten:</h2>
        <ol>
          <li>
            <b>Rufen Sie uns an:</b> Wählen Sie unsere Nummer und schildern Sie
            uns Ihr Problem. Keine komplizierten Fachbegriffe notwendig - wir
            sprechen Ihre Sprache.
          </li>
          <li>
            <b>Kleines Fernwartungsprogramm:</b> Wir zeigen Ihnen, wie Sie ein
            TeamViewer herunterladen. Es ist sicher und einfach. Geben Sie uns
            die angezeigte Nummer, und wir können eine Verbindung herstellen.
          </li>
          <li>
            <b>Entspannen Sie sich:</b> Sobald wir verbunden sind, übernehmen
            wir die Kontrolle, als wären wir Ihr Co-Pilot. Lehnen Sie sich
            zurück und genießen Sie eine Tasse Kaffee - wir erledigen den Rest.
          </li>
        </ol>
        <h2>Keine Überraschungen, Nur Klarheit:</h2>
        <p>
          Wir sind transparent in Bezug auf die Kosten. Die Preise für unsere
          Fernwartungsdienste finden Sie in unserer Preisliste. Als
          Ein-Mann-Betrieb setzen wir auf Ehrlichkeit, Qualität und persönliche
          Betreuung.
        </p>
        <p>
          Sagen Sie "Auf Wiedersehen" zu PC-Sorgen und "Hallo" zu entspanntem
          Fernwartungs-Service. Wir sind bereit, Ihr Computerproblem im
          Handumdrehen zu lösen - rufen Sie uns an und sehen Sie zu, wie Ihr PC
          wieder in Schwung kommt!
        </p>
      </div>
    </div>
  </div>
);
export default Fernwartung;
