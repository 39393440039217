export const Datenschutz = ({ sidebar }) => (
  <div className="template-sidebar">
    <div className="sidebar">{sidebar}</div>
    <div className="content box">
      <div className="inner">
        <h1>Datenschutzerklärung</h1>
        <p>
          Im Folgenden informieren wir über die Erhebung personenbezogener Daten
          bei Nutzung unserer Website. Personenbezogene Daten sind alle Daten,
          die auf Sie persönlich beziehbar sind, z. B. Name, Adresse,
          E-Mail-Adressen, Nutzerverhalten. Ferner informieren wir Sie über die
          Ihnen zustehenden Rechte.
        </p>

        <h2>Allgemeine Informationen</h2>
        <p>
          (1) Im Folgenden informieren wir über die Erhebung personenbezogener
          Daten bei Nutzung unserer Website. Personenbezogene Daten sind alle
          Daten, die auf Sie persönlich beziehbar sind, z. B. Name, Adresse,
          E-Mail-Adressen, Nutzerverhalten. Ferner informieren wir Sie über die
          Ihnen zustehenden Rechte.
        </p>

        <p>
          (2) Verantwortlicher gem. Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung
          (DS-GVO) ist
        </p>
        <p>
          Jonas Nabbefeld Web-Development
          <br />
          Grävenhorsterstr 6<br />
          28755 Bremen
          <br />
          Telefon: 01742851862
        </p>

        <p>(3) Arten der verarbeiteten Daten:</p>
        <ul>
          <li>Bestandsdaten (z.B., Namen, Adressen).</li>
          <li>Kontaktdaten (z.B., E-Mail, Telefonnummern).</li>
          <li>Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos).</li>
          <li>
            Nutzungsdaten (z.B., besuchte Webseiten, Interesse an Inhalten,
            Zugriffszeiten).
          </li>
          <li>
            Meta-/Kommunikationsdaten (z.B., Geräte-Informationen, IP-Adressen).
          </li>
          <li>
            Vertragsdaten (z.B., Vertragsgegenstand, Laufzeit, Kundenkategorie).
          </li>
          <li>
            Zahlungsdaten (z.B., Bankverbindung, Zahlungshistorie) von unseren
            Kunden, Interessenten und Geschäftspartner zwecks Erbringung
            vertraglicher Leistungen, Service und Kundenpflege, Marketing,
            Werbung und Marktforschung.
          </li>
        </ul>

        <p>(4) Kategorien betroffener Personen:</p>
        <ul>
          <li>Besucher unserer Internetseiten.</li>
        </ul>

        <p>(5) Zweck der Verarbeitung</p>
        <ul>
          <li>Bereitstellung der Internetpräsenz</li>
          <li>Kommunikation mit Ihnen</li>
          <li>Sicherheitsmaßnahmen</li>
          <li>Marketing</li>
        </ul>

        <p>
          (6) Falls wir Rechtsgrundlagen in unserer Datenschutzerklärung nicht
          genannt haben, gilt für Besucher, die sich im Geltungsbereich der
          Datenschutzgrundverordnung befinden folgendes:
        </p>
        <ul>
          <li>
            Die Rechtsgrundlage für die Einholung von Einwilligungen ist Art. 6
            Abs. 1 lit. a und Art. 7 DSGVO.
          </li>
          <li>
            Für die Verarbeitung zur Erfüllung unserer Leistungen und
            Durchführung vertraglicher Maßnahmen sowie Beantwortung von Anfragen
            ist Art. 6 Abs. 1 lit. b DSGVO die Rechtsgrundlage.
          </li>
          <li>
            Für die Erfüllung unserer rechtlichen Verpflichtungen ist Art. 6
            Abs. 1 lit. c DSGVO maßgebliche Rechtsgrundlage
          </li>
          <li>
            Für die Verarbeitung von personenbezogenen Daten zur Wahrung
            lebenswichtiger Interessen der betroffenen Persinen ist Art. 6 Abs.
            1 lit. d DSGVO die Rechtsgrundlage.
          </li>
          <li>
            Sofern die Verarbeitung zur Wahrung unserer berechtigten Interessen
            stattfindet, ist Art. 6 Abs. 1 lit. f DSGVO die Rechtsgrundlage.
          </li>
        </ul>

        <p>(7) Datenlöschung und Speicherdauer</p>
        <p>
          Wir löschen oder sperren personenbezogene Daten, sobald die
          Speicherung nicht mehr erforderlich ist. Eine Speicherung kann darüber
          hinaus erfolgen, wenn dies durch den europäischen oder nationalen
          Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen
          Vorschriften, denen der Verantwortliche unterliegt, vorgesehen wurde.
          Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn eine
          durch die genannten Normen vorgeschriebene Speicherfrist abläuft, es
          sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der
          Daten für einen Vertragsabschluss oder eine Vertragserfüllung besteht.
        </p>

        <h2>Ihre Rechte</h2>
        <p>
          Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie
          betreffenden personenbezogenen Daten:
        </p>

        <p>(1) Recht auf Auskunft</p>
        <p>
          Sie haben das Recht, von uns eine Bestätigung darüber zu erhalten, ob
          Sie betreffende personenbezogene Daten verarbeitet werden. Ist dies
          der Fall, so haben Sie ein Recht auf Auskunft über diese
          personenbezogenen Daten und auf die in Art. 15 DS-GVO im einzelnen
          aufgeführten Informationen.
        </p>

        <p>(2) Recht auf Berichtigung</p>
        <p>
          Sie haben das Recht, von uns unverzüglich die Berichtigung Sie
          betreffender unrichtiger personenbezogener Daten und ggf. die
          Vervollständigung unvollständiger personenbezogener Daten zu verlangen
          (Art. 16 DS-GVO).
        </p>

        <p>(3) Recht auf Löschung</p>
        <p>
          Sie haben das Recht, von uns zu verlangen, dass Sie betreffende
          personenbezogene Daten unverzüglich gelöscht werden, sofern einer der
          in Art. 17 DS-GVO im einzelnen aufgeführten Gründe zutrifft, z. B.
          wenn Ihre Daten für die verfolgten Zwecke nicht mehr benötigt werden
          (Recht auf Löschung).
        </p>

        <p>(4) Recht auf Einschränkung der Verarbeitung</p>
        <p>
          Sie haben das Recht, von uns die Einschränkung der Verarbeitung zu
          verlangen, wenn eine der in Art. 18 DS-GVO aufgeführten
          Voraussetzungen gegeben ist, z. B. wenn Sie Widerspruch gegen die
          Verarbeitung eingelegt haben, für die Dauer einer etwaigen Prüfung.
        </p>

        <p>(5) Recht auf Datenübertragbarkeit</p>
        <p>
          Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die
          Sie uns bereitgestellt haben, in einem strukturierten, gängigen und
          maschinenlesebaren Format zu erhalten, und Sie haben das Recht, diese
          Daten einem anderen Verantwortlichen ohne Behinderung durch uns zu
          übermitteln, sofern die Voraussetzungen in Art. 20 DS-GVO vorliegen.
        </p>

        <p>
          (6) Recht auf Widerruf der datenschutzrechtlichen
          Einwilligungserklärung
        </p>
        <p>
          Sie haben das Recht, Ihre Einwilligungserklärung jederzeit zu
          widerrufen. Durch den Widerruf der Einwilligung wird die
          Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
          erfolgten Verarbeitung nicht berührt.
        </p>

        <p>(7) Recht auf Beschwerde bei einer Aufsichtsbehörde</p>
        <p>
          Sie haben das Recht auf Beschwerde bei einer Aufsichtsbehörde,
          insbesondere in dem Mitgliedstaat Ihres Aufenthaltsorts, Ihres
          Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn Sie der
          Ansicht sind, dass die Verarbeitung der Sie betreffenden
          personenbezogenen Daten gegen die DS-GVO verstößt.
        </p>

        <h2>Widerspruchsrecht</h2>
        <p>
          Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten
          Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DS-GVO verarbeitet werden,
          haben Sie das Recht, gemäß Art. 21 DS-GVO Widerspruch gegen die
          Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür
          Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben oder
          sich der Widerspruch gegen Direktwerbung richtet. Im letzteren Fall
          haben Sie ein generelles Widerspruchsrecht, das ohne Angabe einer
          besonderen Situation von uns umgesetzt wird.
        </p>

        <p>
          Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch
          machen, genügt eine E-Mail an info@beispiel.de
        </p>

        <h2>Änderung unserer Datenschutzbestimmungen</h2>
        <p>
          Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie
          stets den aktuellen rechtlichen Anforderungen entspricht oder um
          Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen,
          z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt
          dann die neue Datenschutzerklärung.
        </p>
      </div>
    </div>
  </div>
);

export default Datenschutz;
