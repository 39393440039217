import notFound from "./../assets/images/404.jpg";
export const NotFound = ({ sidebar }) => (
  <div className="template-sidebar">
    <div className="sidebar">{sidebar}</div>
    <div className="content box">
      <div className="inner">
        <img src={notFound} alt="Illustration - Nicht gefunden" />
        <h1>Oha! Hier ist etwas schiefgelaufen.</h1>
        <p>
          Unsere Marketing-Katze hat mal wieder Chaos angerichtet und diese
          Seite verlegt. Aber keine Sorge, wir sind auf der Jagd nach der
          verlorenen Seite!
        </p>
        <h2>In der Zwischenzeit können Sie:</h2>
        <ul>
          <li>
            <b>Die Homepage besuchen:</b> Hier ist weniger Chaos (hoffentlich).
          </li>
          <li>
            <b>Unsere Social-Media-Kanäle durchstöbern:</b> Dort kann sie nicht
            so viel Unsinn anstellen.
          </li>
        </ul>
        <p>
          Oder Sie geben unserer Katze einfach ein Leckerli, damit sie endlich
          aufhört, mit den Links zu spielen!
        </p>
      </div>
    </div>
  </div>
);

export default NotFound;
