import { NavLink } from "react-router-dom";

export const Platjenwerbe = ({ sidebar, links }) => (
  <div className="template-sidebar">
    <div className="sidebar">{sidebar}</div>
    <div className="content box">
      <div className="inner">
        <h1>Computerservice in Platjenwerbe</h1>
        <p>
          Willkommen bei HanseWeb IT, Ihrem vertrauenswürdigen Partner für
          erstklassigen Computerservice in Platjenwerbe, Ritterhude und
          Umgebung. Wir sind stolz darauf, die Bedürfnisse der Gemeinschaft in
          dieser Region zu unterstützen. Unsere Hauptniederlassung befindet sich
          in Bremen Vegesack, und wir sind bestrebt, IT-Lösungen und
          Computerreparaturen in Ihrer Nähe anzubieten.
        </p>

        <h2>Unsere Dienstleistungen in Platjenwerbe und Ritterhude</h2>
        <p>
          Unsere Dienstleistungen sind darauf ausgerichtet, Ihren Computer- und
          IT-Bedürfnissen gerecht zu werden. Egal, ob Sie einen PC-Doktor,
          Computerreparatur oder Unterstützung bei IT-Problemen benötigen, wir
          sind für Sie da. Unsere Services umfassen:
        </p>
        <ul>
          <li>
            <b>Computerreparatur:</b> Unsere Experten sind darauf spezialisiert,
            Computerprobleme schnell und effizient zu beheben. Wir stellen
            sicher, dass Ihr PC wieder einwandfrei funktioniert.
          </li>
          <li>
            <b>PC-Service:</b> Wir bieten umfassende PC-Services, einschließlich
            Hardware-Upgrades, Software-Installation und Fehlerbehebung.
          </li>
          <li>
            <b>Fernwartung:</b> Bei Bedarf können wir Fernwartungsdienste
            anbieten, um Ihre IT-Probleme aus der Ferne zu lösen und Ihnen Zeit
            und Aufwand zu sparen.
          </li>
        </ul>

        <h2>Warum HanseWeb IT wählen?</h2>
        <p>
          Unser Team besteht aus erfahrenen IT-Profis, die sich leidenschaftlich
          für die Zufriedenheit unserer Kunden einsetzen. Wir bieten:
        </p>
        <ul>
          <li>
            <b>Zuverlässigkeit:</b> Wir sind immer für Sie da, wenn Sie uns
            brauchen, und bieten schnelle Lösungen für Ihre
            IT-Herausforderungen.
          </li>
          <li>
            <b>Kompetenz:</b> Unsere Techniker sind hochqualifiziert und
            verfügen über das Wissen, um Ihr IT-Problem effektiv zu lösen.
          </li>
          <li>
            <b>Kundenorientierung:</b> Ihre Zufriedenheit hat für uns oberste
            Priorität. Wir sind bestrebt, die besten Lösungen für Ihre
            Bedürfnisse zu finden.
          </li>
        </ul>

        <h2>Kontaktieren Sie uns in Platjenwerbe, Ritterhude</h2>
        <p>
          Wenn Sie unsere Dienstleistungen in Platjenwerbe und Ritterhude in
          Anspruch nehmen möchten, zögern Sie nicht, uns zu kontaktieren. Wir
          sind nur einen Anruf oder eine E-Mail entfernt und stehen Ihnen mit
          unserem erstklassigen Computerservice zur Verfügung.
        </p>
        {links}
      </div>
    </div>
  </div>
);
export default Platjenwerbe;
