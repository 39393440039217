import { NavLink } from "react-router-dom";

export const ComputerService = ({ sidebar, links }) => (
  <div className="template-sidebar">
    <div className="sidebar">{sidebar}</div>
    <div className="content box">
      <div className="inner">
        <h1>Computer Service in Bremen</h1>
        <p>
          Willkommen bei HanseWeb IT, Ihrem vertrauenswürdigen Partner für
          Computer Service in Bremen und Umgebung. In der heutigen digitalen
          Welt sind Computer und Technologie zu einem wesentlichen Bestandteil
          unseres Lebens und unserer Arbeit geworden. Doch was passiert, wenn
          diese Technologie Probleme bereitet oder eine Auszeit benötigt? Genau
          hier kommen wir ins Spiel.
        </p>
        <h2>
          <NavLink to="/fernwartung">Fernwartung</NavLink>
        </h2>
        <p>
          Manchmal streikt der PC, und wir fragen uns: "Was nun?" Keine Sorge,
          Sie müssen jetzt nicht zum IT-Experten werden oder das Haus verlassen.
          HanseWeb IT ist Ihr Rettungsanker, und wir erledigen das Problem,
          während Sie gemütlich zu Hause bleiben.
        </p>
        <h2>
          <NavLink to="/vor-ort">Vor-Ort-Service</NavLink>
        </h2>
        <p>
          In Fällen, in denen persönliche Aufmerksamkeit und physische
          Unterstützung erforderlich sind, sind wir bereit, zu Ihnen zu kommen.
          Unser Vor-Ort-Service ist Ihre Eintrittskarte zur Sorgenfreiheit. Wir
          kümmern uns um Installationen, Konfigurationen, Verkabelungen und
          Problemlösungen direkt bei Ihnen vor Ort.
        </p>
        <br />
        <p>
          Unsere Dienstleistungen stehen sowohl Privatkunden als auch
          Unternehmen in Bremen und den umliegenden Städten zur Verfügung. Wir
          verstehen, wie wichtig es ist, dass Ihre Technologie reibungslos
          funktioniert, sei es für die Arbeit, das Lernen oder das Vergnügen.
        </p>
        <p>
          Wenn Sie mehr über unsere spezifischen Dienstleistungen erfahren
          möchten, klicken Sie einfach auf die entsprechenden Links oben oder
          setzen Sie sich noch heute mit uns in Verbindung. Wir sind hier, um
          Ihre IT-Probleme zu lösen und Ihnen eine stressfreie technische
          Erfahrung zu bieten. Verlassen Sie sich auf HanseWeb IT für all Ihre
          Computer-Service-Bedürfnisse in Bremen und darüber hinaus!
        </p>
        {links}
      </div>
    </div>
  </div>
);
export default ComputerService;
