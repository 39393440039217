import { NavLink } from "react-router-dom";

export const StMagnus = ({ sidebar, links }) => (
  <div className="template-sidebar">
    <div className="sidebar">{sidebar}</div>
    <div className="content box">
      <div className="inner">
        <h1>Computerservice in St. Magnus, Bremen</h1>
        <p>
          Herzlich willkommen bei HanseWeb IT, Ihrem vertrauenswürdigen
          Computerservice-Partner in St. Magnus, Bremen. Unser Standort in
          Bremen-Vegesack ermöglicht es uns, Kunden in der gesamten Region
          kompetent und zuverlässig zu unterstützen.
        </p>

        <h2>Professionelle Computerreparatur</h2>
        <p>
          Wir sind Ihr Experte für Computerreparaturen in St. Magnus. Egal, ob
          Ihr PC langsam ist, sich unerwartet abschaltet oder andere Probleme
          hat, unsere erfahrenen Techniker sind darauf spezialisiert, Probleme
          schnell zu diagnostizieren und effektiv zu beheben. Wir verstehen, wie
          wichtig ein reibungsloser Betrieb Ihres Computers ist, sei es für
          private oder geschäftliche Zwecke.
        </p>

        <h2>Umfassender PC-Service</h2>
        <ul>
          <li>
            <b>PC-Diagnose:</b> Unsere Diagnosedienste helfen dabei, die genaue
            Ursache von Computerproblemen zu ermitteln.
          </li>
          <li>
            <b>Hardware-Reparatur:</b> Von Festplatten bis zu Bildschirmen, wir
            bieten professionelle Hardware-Reparaturen.
          </li>
          <li>
            <b>Software-Unterstützung:</b> Unsere Experten helfen bei der
            Installation, Aktualisierung und Fehlerbehebung von Software.
          </li>
          <li>
            <b>Virusentfernung:</b> Wir schützen Ihre Daten und Ihren PC vor
            schädlicher Malware und Viren.
          </li>
        </ul>

        <h2>Ihr IT-Partner in St. Magnus</h2>
        <p>
          HanseWeb IT ist stolz darauf, Ihr zuverlässiger IT-Partner in St.
          Magnus, Bremen, zu sein. Unser Team steht Ihnen zur Seite, um Ihre
          technischen Probleme zu lösen und Ihre IT-Infrastruktur zu optimieren.
          Ganz gleich, ob Sie Unterstützung für den Heimgebrauch oder für Ihr
          Unternehmen benötigen, wir bieten maßgeschneiderte Lösungen, die Ihren
          Anforderungen gerecht werden.
        </p>

        <h2>Kontaktieren Sie uns</h2>
        <p>
          Wenn Sie professionelle Computerreparatur, PC-Service und
          IT-Unterstützung in St. Magnus, Bremen, suchen, sind wir für Sie da.
          HanseWeb IT kombiniert lokalen Service mit der Expertise eines
          etablierten IT-Unternehmens. Kontaktieren Sie uns noch heute, um Ihre
          IT-Herausforderungen zu bewältigen und Ihren technischen Bedarf zu
          decken.
        </p>
        {links}
      </div>
    </div>
  </div>
);
export default StMagnus;
