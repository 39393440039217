export const VorOrt = ({ sidebar, links }) => (
  <div className="template-sidebar">
    <div className="sidebar">{sidebar}</div>
    <div className="content box">
      <div className="inner">
        <h1>Vor-Ort Computer Service</h1>
        <p>
          Unser Vor-Ort Service ist mehr als nur Technologie; es ist die Kunst
          der Lösungen, die persönliche Note im digitalen Zeitalter. Lassen Sie
          uns Ihre IT-Herausforderungen meistern und Ihre technischen Probleme
          in die Geschichtsbücher verschwinden. Denn für uns ist IT-Service
          nicht nur eine Aufgabe, sondern unsere Art, die Welt ein bisschen
          besser zu machen.
        </p>
        <h2>Wann ist unser Vor-Ort-Service die richtige Wahl?</h2>
        <ul>
          <li>
            <b>Hardware-Installation:</b> Ob Sie neue Hardware wie Computer,
            Drucker oder Router benötigen - wir sind vor Ort, um alles nahtlos
            einzurichten und sicherzustellen, dass es einwandfrei funktioniert.
          </li>
          <li>
            <b>Netzwerkoptimierung:</b> Für eine optimale Netzwerkleistung kann
            ein Vor-Ort-Besuch erforderlich sein, um Ihre Hardware zu überprüfen
            und sicherzustellen, dass alles reibungslos miteinander
            kommuniziert.
          </li>
          <li>
            <b>Verkabelung und Konnektivität:</b> Wir kümmern uns um Verkabelung
            und spezielle Anschlüsse, um eine sichere und effiziente Verbindung
            zu gewährleisten.
          </li>
          <li>
            <b>Problembehebung vor Ort:</b> Komplexere Probleme, die nicht
            remote gelöst werden können, wie Hardwareausfälle oder umfangreiche
            Softwareprobleme, erfordern unsere persönliche Unterstützung.
          </li>
          <li>
            <b>Datensicherung und Wiederherstellung:</b> Wir richten Backups ein
            und helfen Ihnen bei der Wiederherstellung, um Ihre Daten zu
            schützen.
          </li>
          <li>
            <b>Smart-Home-Einrichtung:</b> Wir unterstützen Sie bei der
            Einrichtung von Smart-Home-Technologien, um Ihr Zuhause
            intelligenter und komfortabler zu gestalten.
          </li>
          <li>
            <b>Sicherheitsüberprüfung:</b> Wir führen Sicherheitsüberprüfungen
            vor Ort durch, um sicherzustellen, dass Ihr Netzwerk und Ihre Geräte
            optimal geschützt sind.
          </li>
        </ul>
        <p>
          Unser Team steht Ihnen zur Verfügung, um sicherzustellen, dass Ihre
          IT-Anforderungen effizient und reibungslos erfüllt werden.
          Kontaktieren Sie uns, um einen Vor-Ort-Termin zu vereinbaren.
        </p>
        {links}
      </div>
    </div>
  </div>
);
export default VorOrt;
