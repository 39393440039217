import { NavLink } from "react-router-dom";

const ServiceItem = ({ data: { image, title, text, link, background } }) => (
  <div className="box">
    {image ? (
      <div className="image" style={{ backgroundColor: background }}>
        <img src={image} alt="Computer Service" />
      </div>
    ) : (
      ""
    )}
    <div className="inner">
      <h2>{title}</h2>
      <p>{text}</p>
      <small>
        <NavLink to={link}>Mehr Erfahren</NavLink>
      </small>
    </div>
  </div>
);
const Startseite = ({ services }) => (
  <div className="grid2">
    {Object.keys(services).map((link, i) => {
      if (services[link].featured) {
        return <ServiceItem key={i} data={services[link]} />;
      }
    })}
  </div>
);

export default Startseite;
