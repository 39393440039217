import { NavLink } from "react-router-dom";

export const Burglesum = ({ sidebar, links }) => (
  <div className="template-sidebar">
    <div className="sidebar">{sidebar}</div>
    <div className="content box">
      <div className="inner">
        <h1>Computerservice in Burglesum, Bremen</h1>
        <p>
          Willkommen bei HanseWeb IT, Ihrem zuverlässigen Partner für
          erstklassigen Computerservice in Burglesum, Bremen, und seiner
          Umgebung. Unser Fokus liegt darauf, Ihre technischen Herausforderungen
          zu bewältigen und Lösungen zu bieten, die perfekt auf Ihre Bedürfnisse
          zugeschnitten sind.
        </p>

        <h2>Unsere breite Palette an Dienstleistungen</h2>
        <p>
          Bei HanseWeb IT bieten wir eine Vielzahl von IT-Dienstleistungen, um
          sicherzustellen, dass Sie die bestmögliche Unterstützung für Ihre
          Technologieanforderungen erhalten. Unser Leistungsangebot umfasst:
        </p>
        <ul>
          <li>
            <b>Computerreparatur:</b> Wenn Ihr Computer Probleme hat, sind wir
            die Experten, die schnelle und effiziente Reparaturen durchführen.
          </li>
          <li>
            <b>PC-Service:</b> Unser PC-Service deckt Wartung, Aufrüstung und
            Fehlerbehebung ab, um sicherzustellen, dass Ihr Computer stets
            reibungslos läuft.
          </li>
          <li>
            <b>Fernwartung:</b> Wir bieten bequeme Fernwartungsdienste, um Ihre
            technischen Probleme aus der Ferne zu lösen.
          </li>
        </ul>

        <h2>Unsere Verpflichtung zur Kundenzufriedenheit</h2>
        <p>
          Bei HanseWeb IT sind wir stolz auf unseren hervorragenden
          Kundenservice. Unser engagiertes Team ist darauf bedacht, Ihnen zu
          helfen, und wir arbeiten hart daran, sicherzustellen, dass Sie mit
          unseren Dienstleistungen zufrieden sind. Wir verstehen, dass
          IT-Probleme frustrierend sein können, und unser Ziel ist es, Ihre
          Technik wieder zum Laufen zu bringen, damit Sie sich auf das
          konzentrieren können, was wirklich wichtig ist.
        </p>

        <h2>Unsere Präsenz in Burglesum und Umgebung</h2>
        <p>
          Unsere Hauptniederlassung befindet sich in Bremen Vegesack, aber
          unsere Dienstleistungen erstrecken sich auf das gesamte Einzugsgebiet
          um unseren Standort, einschließlich Burglesum und umliegende
          Stadtteile. Wir sind stolz darauf, die lokale Gemeinschaft zu
          unterstützen und Unternehmen sowie Privatpersonen bei ihren
          IT-Bedürfnissen zu helfen.
        </p>

        <h2>Ihr zuverlässiger Partner für Computerreparatur und mehr</h2>
        <p>
          HanseWeb IT ist Ihr vertrauenswürdiger Partner für Computerservice in
          Burglesum, Bremen. Wir stehen Ihnen zur Seite, wenn technische
          Probleme auftreten, und sorgen dafür, dass Sie stets gut vernetzt und
          einsatzbereit sind. Kontaktieren Sie uns noch heute, um mehr über
          unsere Dienstleistungen zu erfahren und wie wir Ihnen helfen können.
        </p>
        {links}
      </div>
    </div>
  </div>
);
export default Burglesum;
