import { NavLink } from "react-router-dom";

export const BremenNord = ({ sidebar, links }) => (
  <div className="template-sidebar">
    <div className="sidebar">{sidebar}</div>
    <div className="content box">
      <div className="inner">
        <h1>Ihr Computerservice in Bremen-Nord</h1>
        <p>
          Willkommen bei HanseWeb IT, Ihrem verlässlichen Partner für
          Computerservice in Bremen-Nord und Umgebung. Unser Hauptquartier in
          Bremen Vegesack ermöglicht es uns, Sie schnell und effizient in dieser
          Region zu unterstützen. Wir verstehen die Bedürfnisse unserer Kunden
          und bieten maßgeschneiderte IT-Lösungen.
        </p>

        <h2>Unsere Dienstleistungen in Bremen-Nord</h2>
        <p>
          Bei HanseWeb IT bieten wir eine breite Palette von Dienstleistungen
          für Privatkunden und Unternehmen in Bremen-Nord. Unser Angebot
          umfasst:
        </p>
        <ul>
          <li>
            <b>Computerreparatur:</b> Schnelle und zuverlässige Reparatur von
            Desktop-PCs und Laptops.
          </li>
          <li>
            <b>PC-Service:</b> Profis, die Ihnen bei Problemen mit Ihrem
            Computer zur Seite stehen.
          </li>
          <li>
            <b>Fernwartung:</b> Wir bieten Fernwartungsdienste, um Ihre
            IT-Probleme schnell zu beheben.
          </li>
          <li>
            <b>Vor-Ort-Service:</b> Wenn persönliche Hilfe erforderlich ist,
            sind wir zur Stelle.
          </li>
          <li>
            <b>Webdesign und Entwicklung:</b> Wir gestalten beeindruckende
            Webseiten und entwickeln maßgeschneiderte Webanwendungen.
          </li>
          <li>
            <b>IT-Beratung:</b> Unsere Experten beraten Sie bei IT-Strategie und
            -Optimierung.
          </li>
          <li>
            <b>Notfall-Reparatur:</b> Wir sind 24/7 für Sie da, um IT-Notfälle
            zu bewältigen.
          </li>
        </ul>

        <h2>Warum HanseWeb IT?</h2>
        <p>
          Unsere Kunden in Bremen-Nord vertrauen uns, weil wir nicht nur
          IT-Experten sind, sondern auch lokale Unternehmer. Wir verstehen die
          einzigartigen Anforderungen dieser Region und bieten schnelle,
          effiziente Dienstleistungen mit einem Lächeln.
        </p>
        {links}
      </div>
    </div>
  </div>
);
export default BremenNord;
