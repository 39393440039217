import { NavLink } from "react-router-dom";

export const Ritterhude = ({ sidebar, links }) => (
  <div className="template-sidebar">
    <div className="sidebar">{sidebar}</div>
    <div className="content box">
      <div className="inner">
        <h1>Computerservice in Ritterhude, Bremen</h1>
        <p>
          Willkommen bei HanseWeb IT, Ihrem vertrauenswürdigen Computerservice
          in Ritterhude, Bremen. Wir sind Ihr IT-Partner vor Ort, spezialisiert
          auf Computerreparatur und PC-Service in diesem idyllischen Stadtteil.
          Erfahren Sie, wie wir Ihnen bei Ihren technischen Anliegen helfen
          können.
        </p>

        <h2>Unsere Dienstleistungen</h2>
        <p>
          Unsere breite Palette von Dienstleistungen deckt all Ihre
          IT-Bedürfnisse ab:
        </p>
        <ul>
          <li>
            <b>Computerreparatur:</b> Wenn Ihr Computer Probleme hat, sind wir
            zur Stelle. Wir reparieren Hardware- und Softwareprobleme.
          </li>
          <li>
            <b>PC-Service:</b> Unsere PC-Experten bieten schnelle und
            zuverlässige Lösungen für alle Ihre PC-Anliegen.
          </li>
        </ul>

        <h2>Warum HanseWeb IT?</h2>
        <p>
          Warum sollten Sie sich für unseren Computerservice entscheiden? Hier
          sind einige gute Gründe:
        </p>
        <ul>
          <li>
            <b>Erfahrung:</b> Wir verfügen über langjährige Erfahrung und ein
            kompetentes Team von IT-Profis.
          </li>
          <li>
            <b>Schnelle Lösungen:</b> Unser Ziel ist es, Ihre Probleme so
            schnell wie möglich zu lösen, um Ausfallzeiten zu minimieren.
          </li>
        </ul>

        <h2>Unser Team</h2>
        <p>
          Unser engagiertes Team von IT-Experten steht Ihnen zur Verfügung. Wir
          sind stolz darauf, Ihnen kompetenten Service und freundliche
          Unterstützung zu bieten.
        </p>

        <h2>Kontaktieren Sie uns</h2>
        <p>
          Möchten Sie mehr über unseren Computerservice in Ritterhude erfahren?
          Zögern Sie nicht, uns zu kontaktieren. Wir sind bereit, Ihre
          IT-Herausforderungen anzugehen und Lösungen zu liefern, die Ihnen Zeit
          und Nerven sparen.
        </p>

        <h2>Wir sind Ihre IT-Partner in Ritterhude, Bremen</h2>
        <p>
          HanseWeb IT ist stolz darauf, Ihr IT-Partner in Ritterhude, Bremen, zu
          sein. Wir freuen uns darauf, Ihnen bei allen Ihren IT-Bedürfnissen zu
          helfen und Ihre technischen Probleme zu lösen.
        </p>
        {links}
      </div>
    </div>
  </div>
);
export default Ritterhude;
