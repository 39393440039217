import React from "react";
import mapsLogo from "./assets/images/maps-logo.png";

import "./GoogleReviews.css";

const GoogleReviews = () => {
  const placeID = "ChIJX6EDI6fTtkcRtMOHsvuq1Js";
  const averageRating = 5;
  const reviewCount = 3;
  const googleMapsLink = `https://search.google.com/local/reviews?placeid=${placeID}`;
  const structuredData = {
    "@context": "http://schema.org",
    "@type": "LocalBusiness",
    name: "HanseWeb IT",
    image: "https://hanseweb-it.de/logo512.png",
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: averageRating,
      reviewCount: reviewCount,
    },
  };

  const renderStars = (rating) => {
    const starIcons = [];
    for (let i = 1; i <= 5; i++) {
      starIcons.push(
        <span key={i} className={`star${i <= rating ? " active" : ""}`}>
          &#9733;
        </span>
      );
    }
    return starIcons;
  };

  return (
    <div className="google-reviews box">
      <div className="google-maps-logo">
        <img src={mapsLogo} alt="Google Maps Logo" />
      </div>
      <div className="average-rating">
        {renderStars(averageRating)}
        <p>Average Rating: {averageRating.toFixed(1)}</p>
      </div>
      <div className="reviews-link">
        <a href={googleMapsLink} target="_blank" rel="noopener noreferrer">
          {reviewCount} Reviews lesen
        </a>
      </div>
      <script type="application/ld+json">
        {JSON.stringify(structuredData, null, 2)}
      </script>
    </div>
  );
};

export default GoogleReviews;
