import { NavLink } from "react-router-dom";

export const LesumBremen = ({ sidebar, links }) => (
  <div className="template-sidebar">
    <div className="sidebar">{sidebar}</div>
    <div className="content box">
      <div className="inner">
        <h1>Willkommen bei HanseWeb IT - Computerservice in Lesum, Bremen</h1>
        <p>
          HanseWeb IT ist Ihr kompetenter Ansprechpartner für erstklassigen
          Computerservice und IT-Lösungen in Lesum, Bremen. Wir verstehen die
          Bedeutung zuverlässiger IT-Unterstützung für Unternehmen und
          Privatpersonen in dieser wunderschönen Region, und unser Team ist
          bereit, Ihnen bei all Ihren IT-Bedürfnissen zu helfen.
        </p>

        <h2>Unsere Dienstleistungen in Lesum, Bremen</h2>
        <p>
          Bei HanseWeb IT bieten wir eine breite Palette von Dienstleistungen
          an, die speziell auf die Bedürfnisse unserer Kunden in Lesum und
          Umgebung zugeschnitten sind. Unsere Dienstleistungen umfassen:
        </p>
        <ul>
          <li>
            <b>Computerreparatur</b> - Wir beheben alle Arten von
            Computerproblemen, sei es Hardware- oder Software-basiert, und
            sorgen dafür, dass Ihr System wieder einwandfrei funktioniert.
          </li>
        </ul>
        <ul>
          <li>
            <b>IT-Beratung</b> - Unsere Experten bieten professionelle Beratung,
            um sicherzustellen, dass Ihre IT-Infrastruktur optimal auf Ihre
            Anforderungen abgestimmt ist.
          </li>
        </ul>
        <ul>
          <li>
            <b>Fernwartung</b> - Wir bieten bequeme Fernwartungsdienste, um
            Probleme schnell und effizient zu lösen, ohne dass Sie Ihren
            Standort verlassen müssen.
          </li>
        </ul>

        <h2>Warum HanseWeb IT?</h2>
        <p>
          Wir sind stolz darauf, unseren Kunden in Lesum, Bremen, die
          bestmöglichen IT-Lösungen und den besten Computerservice anzubieten.
          Unsere Mission ist es, Ihnen dabei zu helfen, IT-Sorgen zu minimieren
          und Ihre Technologie optimal zu nutzen. Warum sollten Sie sich für
          HanseWeb IT entscheiden?
        </p>
        <ul>
          <li>
            <b>Erfahrung und Expertise</b> - Unser Team verfügt über jahrelange
            Erfahrung und fundierte Kenntnisse in der IT-Branche.
          </li>
        </ul>
        <ul>
          <li>
            <b>Persönlicher Service</b> - Wir bieten persönlichen und
            maßgeschneiderten Service, der auf Ihre individuellen Anforderungen
            eingeht.
          </li>
        </ul>
        <ul>
          <li>
            <b>Zuverlässigkeit</b> - Wir sind immer für Sie da, um IT-Probleme
            schnell und effizient zu lösen.
          </li>
        </ul>

        <h2>Kontaktieren Sie uns heute</h2>
        <p>
          Wenn Sie in Lesum, Bremen, nach verlässlichem Computerservice und
          IT-Lösungen suchen, sind wir für Sie da. Kontaktieren Sie HanseWeb IT,
          und lassen Sie uns Ihnen bei all Ihren IT-Bedürfnissen behilflich
          sein. Wir freuen uns darauf, Sie als unseren geschätzten Kunden
          begrüßen zu dürfen.
        </p>
        {links}
      </div>
    </div>
  </div>
);
export default LesumBremen;
