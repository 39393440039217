import portfolio1 from "./../assets/images/portfolio-1.png";
import portfolio2 from "./../assets/images/portfolio-2.jpg";
import portfolio3 from "./../assets/images/portfolio-3.png";
export const NotFound = ({ sidebar }) => (
  <div className="template-sidebar">
    <div className="sidebar">{sidebar}</div>
    <div className="content box">
      <div className="inner">
        <h1>Digitale Innovation mit HanseWeb IT</h1>
        <p>
          In einer digitalen Welt, die sich ständig verändert und
          weiterentwickelt, bedeutet Stagnation einen Rückschritt. Um mit der
          Zeit zu gehen und den Bedingungen eines hochdynamischen Umfelds
          gerecht zu werden, brauchen Unternehmen Partner, die ihre Vision
          teilen und zu einer erfolgreichen Realisierung beitragen können. Genau
          hier kommen wir ins Spiel. Durch den Einsatz modernster Technologien
          sowie jahrelanger Erfahrung in Webdesign und Webanwendungen bietet
          HanseWeb IT zukunftssichere Lösungen an, die die Effizienz und den
          Unternehmenswert steigern.
        </p>
        <h2>Referenzen</h2>
        <p>
          Unser Portfolio ist voller Erfolgsgeschichten, die von zufriedenen
          Kunden erzählen. Lassen Sie unsere Projekte für sich selbst sprechen.
        </p>
        <h2>Digitale Steinplanungsplattform</h2>
        <p>
          In Zusammenarbeit mit Architekten und Brancheninsidern entwickelten
          wir eine Webanwendung zur Modernisierung, Verwaltung und Planung von
          Naturstein. Dabei lag unser Fokus auf einer optimalen
          Benutzererfahrung und einer industriegemäßen Implementierung von
          Technologien wie SQL, PHP, Python, JS, jQuery, Konva, HTML und CSS.
          Der direkte und nahtlose Austausch mit den Anwendern ermöglichte uns,
          eine Lösung zu entwickeln, die vollständig auf ihre spezifischen
          Bedürfnisse zugeschnitten ist.
        </p>
        <img className="fw16x9" src={portfolio1} alt="Webdesign Referenz DDL" />
        <h2>Digital Signage Software und Web-App</h2>
        <p>
          Unsere innovative Arbeit hat uns dazu veranlasst, eine Webanwendung
          zur Verwaltung und Fernsteuerung von Signage-Displays zu entwickeln.
          Die Entwicklung zielte darauf ab, die Art und Weise, wie Unternehmen
          ihre Displays steuern, zu revolutionieren und ermöglichte sowohl eine
          synchrone Wiedergabe auf mehreren Bildschirmen als auch eine
          On-Demand-Steuerung. Die Kombination verschiedenster Technologien wie
          SQL, PHP, FFmpeg, Stripe, WordPress, JS, jQuery, HTML und CSS hat uns
          die Möglichkeit gegeben, die Software an die Bedürfnisse des
          jeweiligen Unternehmens anzupassen.
        </p>
        <img
          className="fw16x9"
          src={portfolio2}
          alt="Webdesign Referenz Videon"
        />
        <h2>Somfy POS Wandkonfigurator</h2>
        <p>
          Hier konnten wir unsere Fähigkeiten unter Beweis stellen, indem wir
          ein Tool entwickelten, das Außendienstmitarbeitern von Somfy
          ermöglichte, Displays nach Bedarf mit Produkten auszustatten und zu
          bestellen. Die Einfachheit des Prozesses und zugleich seine
          Wirksamkeit, die wir berücksichtigt haben, schätzten unsere Kunden
          besonders.
        </p>
        <img
          className="fw16x9"
          src={portfolio3}
          alt="Webdesign Referenz Somfy"
        />
        <h2>Und viele mehr...</h2>
        <p>
          Die erwähnten Projekte sind nur ein kleiner Einblick in das, was
          HanseWeb IT bisher erreicht hat. Egal, ob es sich um eine
          Sammlungsdatenbank für ein Museum, eine innovative Lichtsteuerung für
          Warenträger oder die Entwicklung spezifischer
          Content-Management-Systeme handelt - unsere Arbeit ist durch Vielfalt
          und Qualität gekennzeichnet. Mit unserer umfangreichen Expertise in
          den Bereichen Webdesign, Entwicklung und Beratung sind wir der ideale
          Partner für Ihr nächstes IT-Projekt.
        </p>
        <p>
          Verbinden Sie sich mit HanseWeb IT und beginnen Sie Ihre digitale
          Reise mit uns. Wir sind bereit, Ihre Träume zur Realität zu machen.
        </p>
      </div>
    </div>
  </div>
);

export default NotFound;
