import { NavLink } from "react-router-dom";
import "./Footer.css";
export const Footer = () => {
  return (
    <footer>
      <div class="wrap">
        <div class="flex-large">
          <div class="w100">
            <ul>
              <li class="footer-title">Computer Service</li>
              <li>
                <NavLink to="/fernwartung">Fernwartung</NavLink>
              </li>
              <li>
                <NavLink to="/vor-ort">Vor-Ort-Service</NavLink>
              </li>
            </ul>
          </div>
          <div class="w100">
            <ul>
              <li class="footer-title">Webentwicklung</li>
              <li>
                <NavLink to="/firmenwebseiten">Firmenwebseiten</NavLink>
              </li>
              <li>
                <NavLink to="/webanwendungen">Webanwendungen</NavLink>
              </li>
              <li>
                <NavLink to="/referenzen">Referenzen</NavLink>
              </li>
            </ul>
          </div>
          <div class="w100">
            <ul>
              <li class="footer-title">Kontankt</li>
              <li>
                <a href="tel:00491742851862">0174 285 18 62</a>
              </li>
              <li>
                <a href="https://wa.me/message/XUXR6IYGKAWYO1">WhatsApp</a>
              </li>
              <li>
                <a href="mailto:jonas.nabbefeld@outlook.com">E-Mail</a>
              </li>
            </ul>
          </div>
          <div class="w100">
            <ul>
              <li class="footer-title">Rechtliches</li>
              <li>
                <NavLink to="/datenschutz">Datenschutz</NavLink>
              </li>
              <li>
                <NavLink to="/impressum">Impressum</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="wrap flex-center rights">
        <small>© 2023 HanseWeb IT. All rights reserved.</small>
      </div>
    </footer>
  );
};
export default Footer;
