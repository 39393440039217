import React from "react";
import ReactDOM from "react-dom/client";
import { Helmet, HelmetProvider } from "react-helmet-async";

// ICONS
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faSquareWhatsapp,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  NavLink,
} from "react-router-dom";

// IMAGES
import logo from "./assets/images/logo.png";
import portrait from "./assets/images/portrait-sm.png";
import computerService from "./assets/images/computer-service.jpg";
import firmenwebseiten from "./assets/images/firmenwebseiten.jpg";

// COMPONENTS
import "./index.css";
import "./Sidebar.css";
import { Header, Nav } from "./Header";
import Footer from "./Footer";
import Startseite from "./pages/Startseite";
import NotFound from "./pages/NotFound";
import Fernwartung from "./pages/Fernwartung";
import VorOrt from "./pages/VorOrt";
import Firmenwebseiten from "./pages/Firmenwebseiten";
import ComputerService from "./pages/ComputerService";
import Datenschutz from "./pages/Datenschutz";
import Impressum from "./pages/Impessum";
import Referenzen from "./pages/Referenzen";

import ScrollToTop from "./ScrollToTop";
import Webanwendungen from "./pages/Webanwendungen";
import SwbWlanStörung from "./articles/SwbWlanStörung";
import BremenNord from "./locations/BremenNord";
import FargeBremen from "./locations/FargeBremen";
import LesumBremen from "./locations/LesumBremen";
import RekumBremen from "./locations/RekumBremen";
import BeckedorfBremen from "./locations/BeckedorfBremen";
import Ritterhude from "./locations/Ritterhude";
import Burgdamm from "./locations/Burgdamm";
import Vegesack from "./locations/Vegesack";
import Schwanewede from "./locations/Schwanewede";
import Burglesum from "./locations/Burglesum";
import Blumenthal from "./locations/Blumenthal";
import StMagnus from "./locations/StMagnus";
import Grohn from "./locations/Grohn";
import Platjenwerbe from "./locations/Platjenwerbe";
import AumundHammersbeck from "./locations/AumundHammersbeck";

const contactOptions = [
  {
    icon: faSquareWhatsapp,
    link: "https://wa.me/message/XUXR6IYGKAWYO1",
    title: "WhatsApp öffnen",
  },
  { icon: faPhone, link: "tel:00491742851862", title: "Anrufen" },
  {
    icon: faEnvelope,
    link: "mailto:jonas.nabbefeld@outlook.com",
    title: "E-Mail senden",
  },
  {
    icon: faLinkedin,
    link: "https://www.linkedin.com/in/jonasnabbefeldit/",
    title: "LinkedIn öffnen",
  },
];

const menu = {
  Webentwicklung: [
    { link: "/firmenwebseiten", text: "Firmenwebseiten" },
    { link: "/webanwendungen", text: "Webanwendungen" },
    { link: "/referenzen", text: "Referenzen" },
  ],
  "Computer Service": [
    { link: "/fernwartung", text: "Fernwartung" },
    { link: "/vor-ort", text: "Vor-Ort" },
  ],
};

const pages = {
  "/": {
    title: "Startseite",
    text: "",
    image: null,
    featured: false,
    link: "/",
    meta: {
      title: "HanseWeb IT - Web Development und IT Service in Bremen-Nord",
      description:
        "Willkommen bei HanseWeb IT, Ihrem Partner für Computer Service in Bremen-Nord. Wir bieten professionelle Fernwartung und Vor-Ort-Service für Unternehmen. Gestaltung und Entwicklung von Firmenwebseiten und maßgeschneiderten Webanwendungen gehören zu unseren Stärken. Kontaktieren Sie uns noch heute für Ihre IT-Bedürfnisse.",
    },
  },
  "/datenschutz": {
    title: "Datenschutzerklärung",
    text: "",
    image: null,
    featured: false,
    link: "/datenschutz",
    meta: {
      title: "Datenschutzerklärung - HanseWeb IT",
      description: "",
    },
  },
  "/impressum": {
    title: "Impressum",
    text: "",
    image: null,
    featured: false,
    link: "/impressum",
    meta: {
      title: "Impressum - HanseWeb IT",
      description: "",
    },
  },
  "/firmenwebseiten": {
    title: "Firmenwebseiten",
    text: "Webseiten, die Eindruck hinterlassen, sind unser Spezialgebiet. Wir gestalten und entwickeln Ihre Online-Visitenkarte mit dem Ernst eines Geschäftsmannes und dem Flair eines Künstlers. Zeigen Sie der Welt, wer Sie sind.",
    image: firmenwebseiten,
    background: "#fcfefd",
    featured: true,
    link: "/firmenwebseiten",
    meta: {
      title: "Webdesign und Firmenwebseiten aus Bremen - HanseWeb IT",
      description:
        "Erfahren Sie mehr über unsere Webdesign und Firmenwebseiten Dienstleistungen in Bremen. HanseWeb IT verwandelt Ihre Ideen in beeindruckende Online-Präsenzen. Kontaktieren Sie uns für Ihr maßgeschneidertes Webdesign!",
    },
  },
  "/webanwendungen": {
    title: "Webanwendungen",
    text: "Webanwendungen, die Ihren Workflow optimieren und Ihre Konkurrenz verwirren. Wir programmieren, als ob wir Geheimagenten des digitalen Wandels wären. Lassen Sie uns Ihre Ideen in Aktion bringen.",
    image: false,
    featured: true,
    meta: {
      title: "Entwicklung komplexer Webanwendungen Bremen - HanseWeb IT",
      description:
        "Erfahren Sie mehr über unsere Entwicklung komplexer Webanwendungen in Bremen. HanseWeb IT verwandelt Ihre Ideen in leistungsstarke Online-Tools. Kontaktieren Sie uns für maßgeschneiderte Lösungen!",
    },
    link: "/webanwendungen",
  },
  "/referenzen": {
    title: "Referenzen",
    text: "Erfahren Sie, warum HanseWeb IT der richtige Partner für Ihre Webentwicklungsprojekte ist. Mit einer langjährigen Erfahrung und einem Portfolio beeindruckender Referenzen bieten wir modernes Webdesign und zukunftssichere Lösungen, die Ihnen helfen, in einer digitalen Welt erfolgreich zu sein.",
    image: false,
    link: "/referenzen",
    featured: true,
    meta: {
      title: "Referenzen Webdesign und Webentwicklung - HanseWeb IT",
      description:
        "Erfahren Sie, warum HanseWeb IT der richtige Partner für Ihre Webentwicklungsprojekte ist. Mit einer langjährigen Erfahrung und einem Portfolio beeindruckender Referenzen bieten wir modernes Webdesign und zukunftssichere Lösungen, die Ihnen helfen, in einer digitalen Welt erfolgreich zu sein. Kontaktieren Sie uns noch heute.",
    },
  },
  "/computer-service": {
    title: "Computer Service",
    text: "Wenn Ihr Computer mal 'ne Auszeit braucht, ist HanseWeb IT zur Stelle! Wir reparieren, retten und revitalisieren Ihre Technik, als wäre sie unser eigener Laptop in der Not.",
    image: computerService,
    background: "#ffffff",
    featured: true,
    link: "/computer-service",
    meta: {
      title: "Computer Service in Bremen - HanseWeb IT",
      description:
        "Erfahren Sie mehr über unseren professionellen Computer Service in Bremen. HanseWeb IT steht Ihnen zur Seite, um Ihre Technikprobleme zu lösen. Kontaktieren Sie uns für schnelle Hilfe!",
    },
  },
  "/fernwartung": {
    title: "Fernwartung",
    text: "Fernwartung so einfach wie der Kaffee am Morgen! Mit unseren Fernwartungsdiensten sind wir nicht nur Ihr IT-Doktor, sondern auch Ihr persönlicher Technik-Barista. Genießen Sie Support, egal wo Sie gerade sind.",
    image: false,
    featured: true,
    link: "/fernwartung",
    meta: {
      title: "Fernwartung in Bremen und Umgebung - HanseWeb IT",
      description:
        "Erfahren Sie mehr über unsere Fernwartungsdienste in Bremen und Umgebung. HanseWeb IT bietet Ihnen bequeme und schnelle technische Unterstützung. Kontaktieren Sie uns noch heute!",
    },
  },
  "/vor-ort": {
    title: "Vor-Ort-Service",
    text: "Manchmal braucht Ihr IT-Problem persönliche Aufmerksamkeit. Unser Vor-Ort-Service ist Ihre Eintrittskarte zur Sorgenfreiheit. Wir kommen zu Ihnen, schnappen uns das Problem und machen, dass es sich schleicht.",
    image: false,
    featured: true,
    link: "/vor-ort",
    meta: {
      title: "Computer Vor-Ort-Service in Bremen - HanseWeb IT",
      description:
        "Erfahren Sie mehr über unseren Vor-Ort-Service in Bremen. HanseWeb IT bietet persönliche Hilfe für Ihre IT-Herausforderungen. Kontaktieren Sie uns für schnelle Lösungen!",
    },
  },
  "/swb-wlan-stoerung": {
    title: "SWB WLAN Störung",
    text: "",
    image: false,
    link: "/swb-wlan-stoerung",
    featured: false,
    meta: {
      title: "SWB WLAN Störung: Ursachen und Lösungen",
      description:
        "SWB WLAN Störung? Erfahren Sie, wie Sie Probleme beheben und die Verbindung optimieren können. Gratis Erstgespräch für schnelle Lösungen mit HanseWeb IT.",
    },
  },
  "/bremen-nord": {
    title: "Bremen-Nord",
    text: "",
    image: false,
    link: "/bremen-nord",
    featured: false,
    location: true,
    meta: {
      title: "Ihr Computerservice in Bremen-Nord - HanseWeb IT",
      description:
        "Entdecken Sie unseren erstklassigen Computerservice in Bremen-Nord. Wir bieten Computerreparatur, PC-Service und IT-Lösungen. Zuverlässig und kompetent.",
    },
  },
  "/farge-bremen": {
    title: "Farge",
    text: "",
    image: false,
    link: "/farge-bremen",
    featured: false,
    location: true,
    meta: {
      title: "Computerservice in Farge, Bremen - HanseWeb IT",
      description:
        "Farge, Bremen - Ihr Standort für professionellen Computerservice. Computerreparatur, PC-Doktor und IT-Dienstleistungen in Ihrer Nähe.",
    },
  },
  "/lesum-bremen": {
    title: "Lesum",
    text: "",
    image: false,
    link: "/lesum-bremen",
    featured: false,
    location: true,
    meta: {
      title: "Computerservice in Lesum, Bremen - HanseWeb IT",
      description:
        "Lesum, Bremen - Ihr zuverlässiger Partner für Computerreparatur und IT-Services. Entdecken Sie unsere Dienstleistungen in Lesum.",
    },
  },
  "/rekum-bremen": {
    title: "Rekum",
    text: "",
    image: false,
    link: "/rekum-bremen",
    featured: false,
    location: true,
    meta: {
      title: "Computerservice in Rekum, Bremen - HanseWeb IT",
      description:
        "Rekum, Bremen - Ihr Ansprechpartner für Computerreparatur und IT-Dienstleistungen. Wir bieten professionellen Computerservice in Ihrer Nähe.",
    },
  },
  "/beckedorf-bremen": {
    title: "Beckedorf",
    text: "",
    image: false,
    link: "/beckedorf-bremen",
    featured: false,
    location: true,
    meta: {
      title: "Computerservice in Beckedorf, Bremen - HanseWeb IT",
      description:
        "Beckedorf, Bremen - Ihr Computerservice vor Ort. Computerreparatur, PC-Doktor und IT-Lösungen in 31699 Beckedorf, Deutschland.",
    },
  },
  "/ritterhude-bremen": {
    title: "Ritterhude",
    text: "",
    image: false,
    link: "/ritterhude-bremen",
    featured: false,
    location: true,
    meta: {
      title: "Computerservice in Ritterhude, Bremen - HanseWeb IT",
      description:
        "Ritterhude, Bremen - Wir sind Ihr IT-Partner in Ritterhude, Deutschland. Computerreparatur und PC-Service, verlässlich und kompetent.",
    },
  },
  "/burgdamm-bremen": {
    title: "Burgdamm",
    text: "",
    image: false,
    link: "/burgdamm-bremen",
    featured: false,
    location: true,
    meta: {
      title: "Computerservice in Burgdamm, Bremen - HanseWeb IT",
      description:
        "Burgdamm, Bremen - Ihr Computerservice in Ihrer Nähe. Computerreparatur und IT-Lösungen in Burgdamm, Bremen.",
    },
  },
  "/vegesack-bremen": {
    title: "Vegesack",
    text: "",
    image: false,
    link: "/vegesack-bremen",
    featured: false,
    location: true,
    meta: {
      title: "Computerservice in Vegesack, Bremen - HanseWeb IT",
      description:
        "Vegesack, Bremen - Ihr IT-Experte in der Region. Computerreparatur und PC-Service in Vegesack, Bremen.",
    },
  },
  "/schwanewede-bremen": {
    title: "Schwanewede",
    text: "",
    image: false,
    link: "/schwanewede-bremen",
    featured: false,
    location: true,
    meta: {
      title: "Computerservice in Schwanewede, Bremen - HanseWeb IT",
      description:
        "Schwanewede, Bremen - Wir sind Ihr Ansprechpartner für Computerservice. Computerreparatur und IT-Lösungen in Schwanewede.",
    },
  },
  "/burglesum-bremen": {
    title: "Burglesum",
    text: "",
    image: false,
    link: "/burglesum-bremen",
    featured: false,
    location: true,
    meta: {
      title: "Computerservice in Burglesum, Bremen - HanseWeb IT",
      description:
        "Burglesum, Bremen - Ihr zuverlässiger Partner für Computerreparatur und IT-Services. Entdecken Sie unsere Dienstleistungen in Burglesum, Bremen.",
    },
  },
  "/blumenthal-bremen": {
    title: "Blumenthal",
    text: "",
    image: false,
    link: "/blumenthal-bremen",
    featured: false,
    location: true,
    meta: {
      title: "Computerservice in Blumenthal, Bremen - HanseWeb IT",
      description:
        "Blumenthal, Bremen - Wir sind Ihr Computerservice vor Ort. Computerreparatur und IT-Lösungen in Blumenthal, Bremen.",
    },
  },
  "/st-magnus-bremen": {
    title: "St. Magnus",
    text: "",
    image: false,
    link: "/st-magnus-bremen",
    featured: false,
    location: true,
    meta: {
      title: "Computerservice in St. Magnus, Bremen - HanseWeb IT",
      description:
        "St. Magnus, Bremen - Ihr IT-Partner in der Region. Computerreparatur und PC-Service, verlässlich und kompetent.",
    },
  },
  "/grohn-bremen": {
    title: "Grohn",
    text: "",
    image: false,
    link: "/grohn-bremen",
    featured: false,
    location: true,
    meta: {
      title: "Computerservice in Grohn, Bremen - HanseWeb IT",
      description:
        "Grohn, Bremen - Ihr Computerservice in Grohn, 28759 Bremen, Deutschland. Computerreparatur und IT-Lösungen in Ihrer Nähe.",
    },
  },
  "/platjenwerbe-bremen": {
    title: "Platjenwerbe",
    text: "",
    image: false,
    link: "/platjenwerbe-bremen",
    featured: false,
    location: true,
    meta: {
      title: "Computerservice in Platjenwerbe, Bremen - HanseWeb IT",
      description:
        "Platjenwerbe, Ritterhude - Wir sind Ihr IT-Experte in Platjenwerbe, Ritterhude, Deutschland. Computerreparatur und PC-Service, verlässlich und kompetent.",
    },
  },
  "/aumund-hammersbeck-bremen": {
    title: "Aumund-Hammersbeck",
    text: "",
    image: false,
    link: "/aumund-hammersbeck-bremen",
    featured: false,
    location: true,
    meta: {
      title: "Computerservice in Aumund-Hammersbeck, Bremen - HanseWeb IT",
      description:
        "Aumund-Hammersbeck, Bremen - Ihr Ansprechpartner für Computerservice. Computerreparatur und IT-Lösungen in Aumund-Hammersbeck, Bremen.",
    },
  },
};

const Sidebar = (
  <Nav menu={menu} portrait={portrait} contactOptions={contactOptions} />
);
const Standorte = () => {
  const len = Object.keys(pages).length;
  const Links = Object.keys(pages).map((link, index) => {
    const { title, location } = pages[link];
    if (location)
      return (
        <>
          <NavLink key={index} to={link}>
            {title}
          </NavLink>
          {index != len - 1 ? ", " : ""}
        </>
      );
  });
  return (
    <>
      <h2>Einzugsgebiet:</h2>
      <p>{Links}</p>
    </>
  );
};
const Einzugsgebiet = <Standorte pages={pages} />;
pages["/"].content = <Startseite services={pages} />;
pages["/datenschutz"].content = <Datenschutz sidebar={Sidebar} />;
pages["/impressum"].content = <Impressum sidebar={Sidebar} />;
pages["/computer-service"].content = (
  <ComputerService sidebar={Sidebar} links={Einzugsgebiet} />
);
pages["/vor-ort"].content = <VorOrt sidebar={Sidebar} links={Einzugsgebiet} />;
pages["/fernwartung"].content = <Fernwartung sidebar={Sidebar} />;
pages["/firmenwebseiten"].content = <Firmenwebseiten sidebar={Sidebar} />;
pages["/webanwendungen"].content = <Webanwendungen sidebar={Sidebar} />;
pages["/referenzen"].content = <Referenzen sidebar={Sidebar} />;

pages["/swb-wlan-stoerung"].content = <SwbWlanStörung sidebar={Sidebar} />;

pages["/bremen-nord"].content = (
  <BremenNord sidebar={Sidebar} links={Einzugsgebiet} />
);
pages["/farge-bremen"].content = (
  <FargeBremen sidebar={Sidebar} links={Einzugsgebiet} />
);
pages["/lesum-bremen"].content = (
  <LesumBremen sidebar={Sidebar} links={Einzugsgebiet} />
);
pages["/rekum-bremen"].content = (
  <RekumBremen sidebar={Sidebar} links={Einzugsgebiet} />
);
pages["/beckedorf-bremen"].content = (
  <BeckedorfBremen sidebar={Sidebar} links={Einzugsgebiet} />
);
pages["/ritterhude-bremen"].content = (
  <Ritterhude sidebar={Sidebar} links={Einzugsgebiet} />
);
pages["/burgdamm-bremen"].content = (
  <Burgdamm sidebar={Sidebar} links={Einzugsgebiet} />
);
pages["/vegesack-bremen"].content = (
  <Vegesack sidebar={Sidebar} links={Einzugsgebiet} />
);
pages["/schwanewede-bremen"].content = (
  <Schwanewede sidebar={Sidebar} links={Einzugsgebiet} />
);
pages["/burglesum-bremen"].content = (
  <Burglesum sidebar={Sidebar} links={Einzugsgebiet} />
);
pages["/blumenthal-bremen"].content = (
  <Blumenthal sidebar={Sidebar} links={Einzugsgebiet} />
);
pages["/st-magnus-bremen"].content = (
  <StMagnus sidebar={Sidebar} links={Einzugsgebiet} />
);
pages["/grohn-bremen"].content = (
  <Grohn sidebar={Sidebar} links={Einzugsgebiet} />
);
pages["/platjenwerbe-bremen"].content = (
  <Platjenwerbe sidebar={Sidebar} links={Einzugsgebiet} />
);
pages["/aumund-hammersbeck-bremen"].content = (
  <AumundHammersbeck sidebar={Sidebar} links={Einzugsgebiet} />
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Router>
        <ScrollToTop />
        <Header
          menu={menu}
          contactOptions={contactOptions}
          logo={logo}
          portrait={portrait}
        />
        <main className="wrap">
          <Routes>
            {Object.keys(pages).map((link, index) => {
              const {
                meta: { description, title },
                content,
              } = pages[link];
              if (content) {
                return (
                  <Route
                    key={index}
                    path={link}
                    element={
                      <>
                        <Helmet>
                          <title>{title}</title>
                          <meta name="description" content={description} />
                          <link
                            rel="canonical"
                            href={"https://hanseweb-it.de" + link}
                          />
                        </Helmet>
                        {content}
                      </>
                    }
                  />
                );
              }
            })}
            <Route path="*" element={<NotFound sidebar={Sidebar} />} />
          </Routes>
        </main>
        <Footer />
      </Router>
    </HelmetProvider>
  </React.StrictMode>
);
