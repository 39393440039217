import { NavLink } from "react-router-dom";

export const AumundHammersbeck = ({ sidebar, links }) => (
  <div className="template-sidebar">
    <div className="sidebar">{sidebar}</div>
    <div className="content box">
      <div className="inner">
        <h1>HanseWeb IT - Computerservice in Aumund-Hammersbeck, Bremen</h1>
        <p>
          Willkommen bei HanseWeb IT, Ihrem zuverlässigen Partner für
          erstklassigen Computerservice in Aumund-Hammersbeck und Umgebung.
          Unsere Hauptniederlassung in Bremen-Vegesack ist Ihr Tor zu
          professionellen IT-Lösungen und schneller Computerreparatur, und wir
          sind stolz darauf, unseren Kunden im gesamten
          Aumund-Hammersbeck-Gebiet eine erstklassige Betreuung zu bieten.
        </p>
        <h2>Ihr Experte für Computerreparatur</h2>
        <p>
          Bei HanseWeb IT verstehen wir die Bedeutung eines reibungslos
          funktionierenden Computers. Unser Team von erfahrenen Technikern steht
          bereit, um Ihre technischen Probleme zu lösen. Ob es sich um Hardware-
          oder Softwareprobleme handelt, wir sind Ihr Ansprechpartner für
          schnelle und effiziente Computerreparatur.
        </p>
        <h2>Unsere Dienstleistungen in Aumund-Hammersbeck:</h2>
        <ul>
          <li>
            <b>Computerreparatur:</b> Unsere Techniker sind darauf
            spezialisiert, Computerprobleme jeglicher Art zu diagnostizieren und
            zu beheben. Wir wissen, wie wichtig ein reibungslos funktionierender
            Computer für Ihr tägliches Leben und Ihr Geschäft ist.
          </li>
          <li>
            <b>PC-Service:</b> Unser PC-Service bietet schnelle Lösungen für
            PC-Probleme. Egal, ob es um Virenbeseitigung, Hardware-Upgrades oder
            Softwareinstallationen geht, wir sind Ihr zuverlässiger Partner.
          </li>
          <li>
            <b>IT-Lösungen:</b> Wir bieten maßgeschneiderte IT-Lösungen für
            Unternehmen und Privatkunden. Von der Netzwerkoptimierung bis zur
            Datenwiederherstellung sind wir Ihr vertrauenswürdiger IT-Experte.
          </li>
        </ul>
        {links}
      </div>
    </div>
  </div>
);
export default AumundHammersbeck;
