import { NavLink } from "react-router-dom";

export const Vegesack = ({ sidebar, links }) => (
  <div className="template-sidebar">
    <div className="sidebar">{sidebar}</div>
    <div className="content box">
      <div className="inner">
        <h1>Computerservice in Vegesack, Bremen</h1>
        <p>
          Willkommen bei HanseWeb IT, Ihrem vertrauenswürdigen IT-Experten in
          Vegesack, Bremen und der umliegenden Region. Unser Hauptsitz liegt
          hier in Vegesack, und wir sind stolz darauf, unsere Dienstleistungen
          in dieser Gemeinschaft anzubieten.
        </p>

        <h2>Computerreparatur in Vegesack</h2>
        <p>
          Unsere Fachleute sind spezialisiert auf Computerreparatur und
          -wartung. Egal, ob Ihr Laptop Probleme hat oder Ihr PC nicht so läuft,
          wie er sollte - wir sind zur Stelle, um Ihnen zu helfen. Unsere
          schnelle und zuverlässige Servicequalität ist unser Markenzeichen.
        </p>

        <h2>PC-Service für Privatpersonen und Unternehmen</h2>
        <ul>
          <li>
            <b>Privatpersonen:</b> Wir bieten umfassende PC-Services, um
            sicherzustellen, dass Ihr Computer reibungslos funktioniert. Von der
            Fehlerbehebung bis zur Leistungssteigerung sind wir für Sie da.
          </li>
          <li>
            <b>Unternehmen:</b> Unsere IT-Experten bieten maßgeschneiderte
            Lösungen für Ihr Unternehmen. Wir verstehen die speziellen
            Anforderungen von Geschäftskunden und bieten Dienstleistungen, die
            Ihr Unternehmen voranbringen.
          </li>
        </ul>

        <h2>Warum HanseWeb IT wählen?</h2>
        <p>
          Unsere Kunden vertrauen uns aus gutem Grund. Bei HanseWeb IT erhalten
          Sie nicht nur erstklassigen Computerservice, sondern auch
          erstklassigen Kundenservice. Unsere Techniker sind erfahren und gut
          ausgebildet, um Ihre Bedürfnisse zu erfüllen.
        </p>

        <h2>Standortnähe und Erreichbarkeit</h2>
        <p>
          Unsere Hauptniederlassung in Vegesack ermöglicht es uns, schnell auf
          Ihre Anfragen zu reagieren. Sie finden uns in unmittelbarer Nähe, und
          unser Team ist stets erreichbar, um Ihre IT-Probleme zu lösen. Wir
          verstehen die Bedeutung von schnellem und zuverlässigem Service für
          unsere Kunden.
        </p>

        <h2>Vertrauenswürdige IT-Partner in der Region</h2>
        <p>
          Unsere Kunden schätzen uns als vertrauenswürdigen IT-Partner. Wir sind
          stolz darauf, die Gemeinschaft von Vegesack, Bremen und der
          umliegenden Region zu unterstützen. Unser Ziel ist es, Ihre
          technischen Herausforderungen zu meistern und Lösungen zu bieten, die
          zu Ihrem Erfolg beitragen.
        </p>

        <h2>Kontaktieren Sie uns noch heute</h2>
        <p>
          Suchen Sie professionellen Computerservice in Vegesack, Bremen? Wir
          sind bereit, Ihnen zu helfen. Kontaktieren Sie uns noch heute, und
          lassen Sie uns gemeinsam Ihre IT-Anforderungen angehen. HanseWeb IT -
          Ihr IT-Experte in Vegesack und darüber hinaus.
        </p>
        {links}
      </div>
    </div>
  </div>
);
export default Vegesack;
