import { NavLink } from "react-router-dom";

export const Burgdamm = ({ sidebar, links }) => (
  <div className="template-sidebar">
    <div className="sidebar">{sidebar}</div>
    <div className="content box">
      <div className="inner">
        <h1>HanseWeb IT - Computerservice in Burgdamm, Bremen</h1>
        <p>
          Burgdamm, Bremen - Ihr Computerservice in Ihrer Nähe. Wir sind Ihr
          vertrauenswürdiger Partner für Computerreparatur und IT-Lösungen in
          Burgdamm, Bremen.
        </p>

        <h2>Unsere Dienstleistungen in Burgdamm, Bremen</h2>
        <p>
          Wir bieten eine breite Palette von IT-Dienstleistungen, um
          sicherzustellen, dass Ihre Technologie reibungslos funktioniert.
          Unsere Dienstleistungen umfassen:
        </p>
        <ul>
          <li>
            <b>Computerreparatur</b>: Unsere erfahrenen Techniker sind bereit,
            Ihre Computerprobleme zu lösen und Ihre Geräte wieder in Topform zu
            bringen.
          </li>
          <li>
            <b>PC-Service</b>: Ob es sich um Softwareprobleme oder
            Hardware-Upgrades handelt, wir bieten umfassende
            PC-Dienstleistungen.
          </li>
          <li>
            <b>IT-Lösungen</b>: Wir sind Experten in der Bereitstellung
            maßgeschneiderter IT-Lösungen, die auf die Bedürfnisse Ihres
            Unternehmens zugeschnitten sind.
          </li>
        </ul>
        {links}
      </div>
    </div>
  </div>
);
export default Burgdamm;
