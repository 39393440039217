import { NavLink } from "react-router-dom";

export const FargeBremen = ({ sidebar, links }) => (
  <div className="template-sidebar">
    <div className="sidebar">{sidebar}</div>
    <div className="content box">
      <div className="inner">
        <h1>Willkommen bei HanseWeb IT in Farge, Bremen</h1>
        <p>
          Bei HanseWeb IT sind wir stolz darauf, Ihre erste Anlaufstelle für
          professionellen Computerservice in Farge, Bremen, zu sein. Unser
          Hauptstandort in Bremen Vegesack ermöglicht es uns, Sie in dieser
          Region bestmöglich zu unterstützen. Wir bieten eine breite Palette von
          IT-Dienstleistungen, um sicherzustellen, dass Ihre technischen
          Bedürfnisse erfüllt werden.
        </p>

        <h2>Unsere Dienstleistungen in Farge, Bremen</h2>
        <p>
          Als Ihr lokaler Computerservice-Anbieter in Farge, Bremen, bieten wir
          Folgendes:
        </p>
        <ul>
          <li>
            <b>Computerreparatur:</b> Unsere Experten stehen bereit, um Ihnen
            bei der Fehlerbehebung und Reparatur Ihrer Computer und Laptops zu
            helfen.
          </li>
          <li>
            <b>PC-Doktor:</b> Wenn Ihr Computer Probleme hat, sind wir der
            PC-Doktor, dem Sie vertrauen können, um schnelle und effiziente
            Lösungen zu bieten.
          </li>
          <li>
            <b>IT-Dienstleistungen:</b> Wir bieten umfassende
            IT-Dienstleistungen, um sicherzustellen, dass Ihre
            Geschäftsanforderungen erfüllt werden, von Netzwerkunterstützung bis
            zur Softwareentwicklung.
          </li>
        </ul>

        <h2>Warum HanseWeb IT wählen?</h2>
        <p>
          Bei HanseWeb IT verstehen wir die Bedeutung einer verlässlichen
          IT-Infrastruktur. Unser engagiertes Team arbeitet hart daran, um
          sicherzustellen, dass Ihre Technologie reibungslos funktioniert. Wir
          zeichnen uns durch unsere Erfahrung, Fachkenntnisse und unsere Liebe
          zur IT aus.
        </p>

        <h2>Ihr lokaler Partner für Computerservice</h2>
        <p>
          Als Ihr lokaler Computerservice-Partner in Farge, Bremen, sind wir
          stets bereit, Ihre IT-Herausforderungen anzugehen. Kontaktieren Sie
          uns noch heute, und lassen Sie uns Ihnen helfen, Ihre technischen
          Probleme zu lösen.
        </p>

        <h2>Kontaktieren Sie uns</h2>
        <p>
          Wir stehen Ihnen zur Verfügung, um Ihre Fragen zu beantworten und
          Ihnen die bestmögliche IT-Unterstützung zu bieten. Zögern Sie nicht,
          uns zu kontaktieren, um mehr über unsere Dienstleistungen in Farge,
          Bremen, zu erfahren.
        </p>
        {links}
      </div>
    </div>
  </div>
);
export default FargeBremen;
