import { NavLink } from "react-router-dom";

export const Grohn = ({ sidebar, links }) => (
  <div className="template-sidebar">
    <div className="sidebar">{sidebar}</div>
    <div className="content box">
      <div className="inner">
        <h1>Professioneller Computerservice in Grohn und Umgebung</h1>
        <p>
          Willkommen bei HanseWeb IT, Ihrem vertrauenswürdigen Partner für
          Computerservice in Grohn, Bremen. Wir bieten maßgeschneiderte
          IT-Lösungen und Computerreparaturdienste in 28759 und Umgebung. Unser
          Ziel ist es, Ihre technischen Probleme zu lösen und Ihnen einen
          erstklassigen Service zu bieten.
        </p>

        <h2>Ihre vertrauenswürdige IT-Anlaufstelle in Grohn</h2>
        <p>
          Bei HanseWeb IT verstehen wir die Bedeutung einer zuverlässigen
          IT-Infrastruktur für Ihr Unternehmen oder Ihren privaten Gebrauch.
          Unsere Hauptniederlassung in Bremen-Vegesack bildet das Herz unseres
          Unternehmens, und unsere Dienstleistungen erstrecken sich über Grohn
          und die umliegenden Gebiete.
        </p>

        <h2>Unsere Dienstleistungen in Grohn und 28759 Bremen, Deutschland:</h2>
        <ul>
          <li>
            <b>Computerreparatur:</b> Unser erfahrenes Team steht Ihnen zur
            Seite, um Hardware- und Softwareprobleme an Laptops und Desktops
            schnell und effizient zu beheben.
          </li>
          <li>
            <b>PC-Service:</b> Wir bieten umfassenden PC-Service, der Wartung,
            Aufrüstung und Fehlerbehebung umfasst, um sicherzustellen, dass Ihr
            Computer einwandfrei funktioniert.
          </li>
          <li>
            <b>Fernwartung:</b> Dank unserer Fernwartungsdienste können wir Ihre
            IT-Probleme online beheben, was Zeit und Aufwand spart.
          </li>
          <li>
            <b>Vor-Ort-Service:</b> Wenn eine persönliche Betreuung erforderlich
            ist, sind wir vor Ort, um Ihre IT-Herausforderungen zu bewältigen.
          </li>
          <li>
            <b>Webdesign und Entwicklung:</b> Wir gestalten beeindruckende
            Firmenwebseiten und entwickeln maßgeschneiderte Webanwendungen, um
            Ihr Unternehmen online zu repräsentieren.
          </li>
        </ul>

        <h2>Warum HanseWeb IT?</h2>
        <p>
          Unsere Dienstleistungen zeichnen sich durch Qualität, Fachwissen und
          Kundenzufriedenheit aus. Wir verstehen die einzigartigen Anforderungen
          unserer Kunden in Grohn und Bremen, und unser Team arbeitet hart
          daran, maßgeschneiderte IT-Lösungen zu bieten, die Ihren Erwartungen
          entsprechen.
        </p>
        <p>
          Kontaktieren Sie HanseWeb IT, um Ihren nächsten IT-Bedarf in Grohn zu
          decken. Wir sind stolz darauf, Ihre lokale IT-Anlaufstelle zu sein und
          Ihnen bei Computerreparatur, PC-Service und Webentwicklung zu helfen.
        </p>
        {links}
      </div>
    </div>
  </div>
);
export default Grohn;
