import { NavLink } from "react-router-dom";

export const BeckedorfBremen = ({ sidebar, links }) => (
  <div className="template-sidebar">
    <div className="sidebar">{sidebar}</div>
    <div className="content box">
      <div className="inner">
        <h1>HanseWeb IT - Ihr Computerservice in Beckedorf, Bremen</h1>
        <p>
          Willkommen bei HanseWeb IT, Ihrem zuverlässigen Computerservice in
          Beckedorf, Bremen. Wir sind stolz darauf, erstklassige
          IT-Dienstleistungen anzubieten, um Ihre Technikprobleme zu lösen und
          Ihr digitales Leben zu verbessern. Wenn Sie in Beckedorf oder der
          umliegenden Region sind, sind wir Ihr Ansprechpartner für
          Computerreparatur, PC-Doktor und IT-Lösungen.
        </p>

        <h2>Unsere Dienstleistungen in Beckedorf, Bremen</h2>
        <p>
          Unsere Mission ist es, Ihnen zu helfen, die Vorteile der modernen
          Technologie in vollen Zügen zu nutzen. Hier sind einige unserer
          Hauptdienstleistungen:
        </p>
        <ul>
          <li>
            <b>Computerreparatur:</b> Wir bieten schnelle und zuverlässige
            Computerreparaturdienste, um Ihre Geräte wieder zum Laufen zu
            bringen. Egal, ob es sich um Hardwareprobleme, Softwarefehler oder
            Vireninfektionen handelt, wir sind hier, um zu helfen.
          </li>
          <li>
            <b>PC-Doktor:</b> Unsere PC-Experten diagnostizieren und beheben
            Probleme mit Ihrem Desktop- oder Laptop-Computer. Wir sorgen dafür,
            dass Ihr Computer optimal funktioniert und schützen Ihre Daten.
          </li>
          <li>
            <b>IT-Lösungen:</b> Wir bieten umfassende IT-Lösungen für
            Privatpersonen und Unternehmen in Beckedorf, Bremen und Umgebung.
            Unser Team ist darauf spezialisiert, maßgeschneiderte Lösungen zu
            entwickeln, die Ihren Bedürfnissen entsprechen.
          </li>
        </ul>
        {links}
      </div>
    </div>
  </div>
);
export default BeckedorfBremen;
