import { NavLink } from "react-router-dom";
import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";

import "./Header.css";
import GoogleReviews from "./GoogleReviews";

class ContactIcons extends React.Component {
  render() {
    return this.props.options.map(({ icon, link, title }, i) => {
      return (
        <div key={i} className="w25 blr">
          <a href={link} title={title} target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={icon} />
          </a>
        </div>
      );
    });
  }
}

const Profile = ({ portrait, callout }) => {
  return (
    <div className="grid-profile bt">
      <div>
        <img className="portrait" src={portrait} alt="Jonas Nabbefeld" />
      </div>
      <div className="flex-center callout">{callout}</div>
    </div>
  );
};
const ListItem = ({ link, text, click }) => (
  <li>
    <NavLink to={link} onClick={click}>
      {text}
    </NavLink>
  </li>
);
const MenuList = ({ category, items, click }) => {
  return (
    <ul className="bt nav">
      <li className="menu-cat">{category}</li>
      {items.map(({ link, text }, i) => (
        <ListItem key={i} link={link} text={text} click={click} />
      ))}
    </ul>
  );
};
const Menu = ({ menu, click }) => {
  const Menu = [];
  for (let category in menu) {
    Menu.push(
      <MenuList
        key={category}
        category={category}
        items={menu[category]}
        click={click}
      />
    );
  }
  return Menu;
};
export const Nav = ({ contactOptions, portrait, menu, click }) => (
  <nav className="wrap">
    <div className="nav-content">
      <div>
        <div className="flex w100 bt">
          <ContactIcons options={contactOptions} />
        </div>
        <Profile
          portrait={portrait}
          callout={
            <i>
              Heute von <b className="nobreak">8 bis 18 Uhr</b> für dich
              erreichbar.
            </i>
          }
        />
      </div>
      <Menu menu={menu} click={click} />
      <GoogleReviews />
    </div>
  </nav>
);
export class Header extends React.Component {
  state = {
    menuOpen: false,
  };
  toggleMenu = () => {
    this.setState({ menuOpen: !this.state.menuOpen });
  };
  closeMenu = () => {
    this.setState({ menuOpen: false });
  };
  render() {
    const menuOpen = this.state.menuOpen;
    const { menu, contactOptions, portrait, logo } = this.props;
    return (
      <header>
        <div className="flex wrap">
          <div>
            <NavLink to="/">
              <img
                className="logo"
                src={logo}
                alt="HanseWeb IT"
                onClick={this.closeMenu}
              />
            </NavLink>
          </div>
          <div className="spacer"></div>
          <div className={menuOpen ? "menu-trigger" : ""}>
            <FontAwesomeIcon
              className="menu"
              icon={menuOpen ? faXmark : faBars}
              onClick={this.toggleMenu}
            />
          </div>
          <div className={menuOpen ? "darken" : ""}></div>
          {menuOpen ? (
            <Nav
              contactOptions={contactOptions}
              portrait={portrait}
              menu={menu}
              click={this.closeMenu}
            />
          ) : (
            ""
          )}
        </div>
      </header>
    );
  }
}

export default Header;
