import { NavLink } from "react-router-dom";

export const RekumBremen = ({ sidebar, links }) => (
  <div className="template-sidebar">
    <div className="sidebar">{sidebar}</div>
    <div className="content box">
      <div className="inner">
        <h1>Willkommen bei HanseWeb IT in Rekum, Bremen</h1>
        <p>
          Wir sind Ihr zuverlässiger Computerservice in Rekum und der näheren
          Umgebung. Wir bieten professionelle IT-Dienstleistungen, die auf Ihre
          individuellen Anforderungen zugeschnitten sind.
        </p>

        <h2>Unsere Dienstleistungen in Rekum</h2>
        <ul>
          <li>
            <b>Computerreparatur:</b> Egal, ob Ihr Laptop langsam ist oder Ihr
            PC plötzlich den Dienst quittiert hat, wir sind hier, um zu helfen.
            Unsere erfahrenen Techniker beheben Computerprobleme aller Art.
          </li>
          <li>
            <b>Fernwartung:</b> Dank unserer Fernwartungsdienste können wir
            Computerprobleme schnell und effizient beheben, ohne dass Sie Ihr
            Zuhause verlassen müssen.
          </li>
          <li>
            <b>IT-Unterstützung vor Ort:</b> Wenn Ihr IT-Problem eine
            persönliche Lösung erfordert, sind unsere Techniker vor Ort in Rekum
            zur Stelle.
          </li>
          <li>
            <b>Firmenwebseiten und Webanwendungen:</b> Wir gestalten und
            entwickeln maßgeschneiderte Webseiten und Anwendungen, um Ihr
            Unternehmen online optimal zu präsentieren.
          </li>
          <li>
            <b>Zuverlässiger Service:</b> Unsere Dienstleistungen zeichnen sich
            durch Qualität, Schnelligkeit und persönlichen Service aus.
          </li>
        </ul>

        <h2>Warum HanseWeb IT wählen?</h2>
        <p>
          Wir verstehen, dass Ihre IT-Anforderungen einzigartig sind. Bei
          HanseWeb IT nehmen wir uns die Zeit, Ihre Bedürfnisse zu verstehen und
          maßgeschneiderte Lösungen anzubieten. Unser Team von Experten verfügt
          über das Fachwissen und die Erfahrung, um Ihre IT-Herausforderungen zu
          bewältigen, sei es in Rekum, Bremen-Nord oder der weiteren Umgebung.
          Wir sind stolz darauf, unseren Kunden hochwertigen Service und
          persönliche Unterstützung zu bieten. HanseWeb IT - Ihr
          vertrauenswürdiger Computerservice in Rekum und darüber hinaus.
        </p>
        {links}
      </div>
    </div>
  </div>
);
export default RekumBremen;
