import { NavLink } from "react-router-dom";

export const Schwanewede = ({ sidebar, links }) => (
  <div className="template-sidebar">
    <div className="sidebar">{sidebar}</div>
    <div className="content box">
      <div className="inner">
        <h1>Computerservice in Schwanewede, Bremen</h1>
        <p>
          Willkommen bei HanseWeb IT - Ihrem zuverlässigen Ansprechpartner für
          Computerservice in Schwanewede und Umgebung. Unsere Hauptniederlassung
          in Bremen Vegesack ermöglicht es uns, Kunden in Schwanewede mit
          erstklassigem IT-Support und Computerreparatur zu versorgen.
        </p>

        <h2>Unsere Dienstleistungen</h2>
        <p>
          Unsere Dienstleistungen in Schwanewede sind darauf ausgerichtet, Ihre
          technischen Probleme schnell und effizient zu lösen. Wir bieten:
        </p>
        <ul>
          <li>
            <b>Computerreparatur</b>: Unser erfahrenes Team steht bereit, um
            Probleme mit Ihrem PC oder Laptop zu beheben. Egal, ob es sich um
            Hardware- oder Softwareprobleme handelt, wir sind zur Stelle.
          </li>
          <li>
            <b>PC-Service</b>: Wir bieten umfassenden PC-Service, um
            sicherzustellen, dass Ihre Geräte reibungslos funktionieren. Von der
            Fehlerbehebung bis zur Wartung sind wir für Sie da.
          </li>
          <li>
            <b>Fernwartung</b>: Mit unserer Fernwartung können wir viele
            Probleme ohne zeitaufwändige Vor-Ort-Besuche lösen. Das spart Zeit
            und minimiert Ausfallzeiten.
          </li>
        </ul>

        <h2>Warum HanseWeb IT wählen?</h2>
        <p>
          Wir verstehen, dass Vertrauen und Qualität in der IT-Branche von
          entscheidender Bedeutung sind. Hier sind einige Gründe, warum Sie
          HanseWeb IT als Ihren Computerservice-Partner in Schwanewede wählen
          sollten:
        </p>
        <ul>
          <li>
            <b>Erfahrung</b>: Mit Jahren der Erfahrung in der Branche haben wir
            eine breite Palette von IT-Problemen erfolgreich gelöst.
          </li>
          <li>
            <b>Kundenorientierung</b>: Wir hören auf Ihre Bedürfnisse und bieten
            maßgeschneiderte Lösungen, die zu Ihnen passen.
          </li>
          <li>
            <b>Zuverlässigkeit</b>: Unsere Kunden vertrauen auf unsere schnelle
            Reaktionszeit und effektive Problembehebung.
          </li>
          <li>
            <b>Kompetenz</b>: Unser Team besteht aus Fachleuten mit umfassendem
            Fachwissen in der IT-Welt.
          </li>
        </ul>

        <h2>Kontaktieren Sie uns</h2>
        <p>
          Wenn Sie in Schwanewede nach professionellem Computerservice suchen,
          sind wir für Sie da. Kontaktieren Sie HanseWeb IT noch heute, um Ihre
          IT-Herausforderungen zu besprechen und Lösungen zu finden, die zu
          Ihrem Budget und Ihren Anforderungen passen.
        </p>
        {links}
      </div>
    </div>
  </div>
);
export default Schwanewede;
